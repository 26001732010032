import * as ActionCustomerFiture from '../../action/Customer/ActionCustomerFiture';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_customerFiture() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Setting/CustomFeature/Customer/' +
        IdCustomer,
      { headers: config }
    );

    if (data === '') {
      yield put(ActionCustomerFiture.changeTypeFiture('Add'));
    } else {
      yield put(ActionCustomerFiture.changeTypeFiture('Detail'));
    }

    yield put(ActionCustomerFiture.changeIdFiture(data.id));
    yield put(ActionCustomerFiture.changeSmsDelivery(data.smSdelivery));
    yield put(ActionCustomerFiture.changeSmsDelivered(data.smSdelivered));
    yield put(ActionCustomerFiture.changeCall(data.call));
    yield put(ActionCustomerFiture.changeIsRadius(data.zonasi || false));
    yield put(
      ActionCustomerFiture.changeIsRequestPhone(data.isRequestPhone || false)
    );
    yield put(
      ActionCustomerFiture.changeIsHistory(data.hideManualsHistory || false)
    );
    yield put(ActionCustomerFiture.changeRadiusMeters(data.radius || 1000));
    yield put(ActionCustomerFiture.changeSenderAlias(data.senderAlias));
    yield put(ActionCustomerFiture.changeSmsDeliveryBody(data.smSdeliveryBody));
    yield put(
      ActionCustomerFiture.changeSmsDeliveredBody(data.smSdeliveredBody)
    );
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const propsError = ErrorMassage('Customer Fitur');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_customerFiture() {
  yield takeLatest('CHANGE_DETAIL_CUSTOMER_FITURE', S_customerFiture);
}
