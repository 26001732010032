import React, { Component } from 'react';

import ActivationVoipContainer from '../../../containers/Content/Customer/Voip/ActivationVoipContainer';
import CustomerConfigFitureContainer from '../../../containers/Content/Customer/CustomerConfigFitureContainer';
import CustomerCustomCsvContainer from '../../../containers/Content/Customer/CustomCsvCustomerContainer';
import CustomerDetailConfigReportContainer from '../../../containers/Content/Customer/CustomerDetailConfigReportContainer';
import CustomerStatusConfigContainer from '../../../containers/Content/Customer/CustomerStatusConfigContainer';
import FormCsvCustomerContainer from '../../../containers/Content/Customer/FormCsvCustomerContainer';
import CustomerReport from 'module/Customer/Report/container';
import TemplateUpload from 'module/Customer/TemplateUpload/container';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

interface IProps {
  handleChangeTabsCustomer: any;
  lastAccess: any;
}
export default class IndexCustomerConfigComponent extends Component<IProps> {
  render() {
    let a = '1';

    if (
      this.props.lastAccess === 'configReport' ||
      this.props.lastAccess === 'DetailConfigReport'
    ) {
      a = '4';
    } else if (this.props.lastAccess === 'voipSetting') {
      a = '3';
    } else if (
      this.props.lastAccess === 'csvSetting' ||
      this.props.lastAccess === 'DetailCustomCsv' ||
      this.props.lastAccess === 'EditCustomCsv' ||
      this.props.lastAccess === 'AddCustomCsv'
    ) {
      a = '2';
    } else {
      a = '1';
    }
    return (
      <div>
        <Tabs
          style={{ padding: '35px' }}
          defaultActiveKey={a}
          onChange={this.props.handleChangeTabsCustomer}
        >
          <TabPane tab={<b>Status Pengiriman</b>} key="1">
            <CustomerStatusConfigContainer />
          </TabPane>
          <TabPane tab={<b>Template Unggah</b>} key="2">
            {this.props.lastAccess === 'DetailCustomCsv' ||
            this.props.lastAccess === 'EditCustomCsv' ? (
              <CustomerCustomCsvContainer />
            ) : this.props.lastAccess === 'AddCustomCsv' ? (
              <FormCsvCustomerContainer />
            ) : (
              <TemplateUpload />
            )}
          </TabPane>
          <TabPane tab={<b>Fitur</b>} key="3">
            <CustomerConfigFitureContainer />
          </TabPane>
          <TabPane tab={<b>VOIP</b>} key="4">
            <ActivationVoipContainer />
          </TabPane>
          <TabPane
            tab={
              <b>
                {this.props.lastAccess === 'DetailConfigReport'
                  ? 'Laporan/Pengaturan'
                  : 'Laporan'}
              </b>
            }
            key="5"
          >
            {this.props.lastAccess === 'DetailConfigReport' ? (
              <CustomerDetailConfigReportContainer />
            ) : (
              <CustomerReport />
            )}
          </TabPane>
        </Tabs>
        ,
      </div>
    );
  }
}
