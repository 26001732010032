/* eslint-disable no-mixed-operators */

import '../../../index.css';

import {
  Checkbox,
  Col,
  Form,
  Icon,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';
import {
  CustomReportFormat,
  CustomReportFormatType,
  HistoryType,
} from '../../../core/enumerable/General';
import React, { Component } from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../materials/ModalMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';

const { Option } = Select;

interface IProps {
  visible: any;
  jenisToggle: any;
  isLoading: any;
  LoadingCustomer: any;
  TypeLoadingCustomer: any;

  GetCustomerName: any;
  GetHeader: any;
  GetFormat: any;
  GetPosition: any;
  GetValue: any;
  GetValueDefault: any;
  GetSourceTable: any;
  GetFormatType: any;
  GetSourceColumn: any;
  GetSourceColumnSec: any;
  GetListSourceColumn: any;
  GetListSourceColumnSec: any;
  GetFormatColumn: any;
  GetCharLength: any;
  GetHistoryType: any;
  GetDeliveryTo: any;
  GetSubColumn: any;
  GetSubColumnSec: any;
  GetListSubColumn: any;
  GetListSubColumnSec: any;
  GetReplaceSuccess: any;
  GetReplaceReturn: any;
  GetReplaceOnProcess: any;
  GetReplaceOnHold: any;
  GetCheckBoxPetik: any;
  GetCheckBoxOthers: any;

  ValidateHeader: any;
  ValidatePosition: any;
  ValidateFormat: any;
  ValidateValueDefault: any;
  ValidateSourceTable: any;
  ValidateFormatType: any;
  ValidateSourceColumn: any;
  ValidateSourceColumnSec: any;
  ValidateFormatColumn: any;
  ValidateHistoryType: any;
  ValidateDeliveryTo: any;
  ValidateSubColumn: any;
  ValidateSubColumnSec: any;
  ValidateReplaceSuccess: any;
  ValidateReplaceReturn: any;
  ValidateReplaceOnProcess: any;
  ValidateReplaceOnHold: any;

  helpValidateHeader: any;
  helpValidatePosition: any;
  helpValidateFormat: any;
  helpValidateValueDefault: any;
  helpValidateSourceTable: any;
  helpValidateFormattype: any;
  helpValidateSourceColumn: any;
  helpValidateSourceColumnSec: any;
  helpValidateFormatColumn: any;
  helpValidateHistoryType: any;
  helpValidateDeliveryTo: any;
  helpValidateSubColumn: any;
  helpValidateSubColumnSec: any;
  helpValidateReplaceSuccess: any;
  helpValidateReplaceReturn: any;
  helpValidateReplaceOnProcess: any;
  helpValidateReplaceOnHold: any;

  handleChangeHeader: any;
  handleChangePosition: any;
  handleChangeFormat: any;
  handleChangeValue: any;
  handleChangeValueDefault: any;
  handleChangeSourceTable: any;
  handleChangeFormatType: any;
  handleChangeSourceColumn: any;
  handleChangeSourceColumnSec: any;
  handleChangeFormatColumn: any;
  handleChangeHistoryType: any;
  handleChangeDeliveryTo: any;
  handleChangeSubColumn: any;
  handleChangeSubColumnSec: any;
  handleChangeReplaceSuccess: any;
  handleChangeReplaceReturn: any;
  handleChangeReplaceOnProcess: any;
  handleChangeReplaceOnHold: any;
  handleChangeCheckBoxPetik: any;
  handleChangeCheckBoxOthers: any;
  handleChangeCharLength: any;

  handleGetListSourceColumn: any;
  handleGetListSourceColumnSec: any;
  handleGetListSubColumn: any;
  handleGetListSubColumnSec: any;

  handleBlurSourceTable: any;
  handleBlurFormat: any;
  handleBlurFormatType: any;
  handleBlurSourceColumn: any;
  handleBlurSourceColumnSec: any;
  handleBlurHistoryType: any;
  handleBlurDeliveryTo: any;
  handleBlurSubColumn: any;
  handleBlurSubColumnSec: any;

  handleResetFormat: any;
  handleCloseModal: any;
  handleSubmitCustomReport: any;
}
export default class FormCustomDataReportCustomerComponent extends Component<IProps> {
  render() {
    const checkboxSpan = {
      paddingLeft: 15,
      fontWeight: 500,
      color: '#969696',
    };
    const valiateBtn = () => {
      const coreValidate =
        this.props.GetFormat === null ||
        this.props.GetHeader === null ||
        this.props.ValidateHeader === 'error'
          ? true
          : false;
      const isSubColumn =
        this.props.GetSourceColumn === 'AdditionalData(STRING)' ||
        this.props.GetSourceColumn === 'CustomStatusCode(STRING)' ||
        this.props.GetSourceColumn === 'ReceiverLocation(STRING)' ||
        this.props.GetSourceColumn === 'ShipToAddress(STRING)'
          ? true
          : false;
      const isSecSubColumn =
        this.props.GetSourceColumnSec === 'AdditionalData(STRING)' ||
        this.props.GetSourceColumnSec === 'CustomStatusCode(STRING)' ||
        this.props.GetSourceColumnSec === 'ReceiverLocation(STRING)' ||
        this.props.GetSourceColumnSec === 'ShipToAddress(STRING)'
          ? true
          : false;

      const formatType =
        this.props.GetFormatType === 'DateTime' ||
        this.props.GetFormatType === 'SuccessTime' ||
        this.props.GetFormatType === 'ReturnTime' ||
        this.props.GetFormatType === 'Imbuhan'
          ? true
          : false;
      const validateReplaceStatus =
        !this.props.GetReplaceSuccess === false &&
        !this.props.GetReplaceReturn === false &&
        !this.props.GetReplaceOnProcess === false &&
        (this.props.GetSourceTable === 'DeliveryHistory'
          ? !this.props.GetReplaceOnHold === false
          : true)
          ? false
          : true;

      if (this.props.GetFormat === 'SourceTabel') {
        if (
          (this.props.GetSourceTable === 'Delivery' ||
            this.props.GetSourceTable === 'DeliveryHistory') &&
          (this.props.GetSourceColumn === 'DeliveryStatus' ||
            this.props.GetSourceColumn === 'DeliveryHistory') &&
          this.props.GetFormatType === 'DeliveryStatus'
        ) {
          return validateReplaceStatus;
        } else if (
          this.props.GetSourceTable === 'Delivery' &&
          isSubColumn === true &&
          formatType === true
        ) {
          //1 sumber kolom "true", format tipe "true"
          return coreValidate === false &&
            this.props.GetSubColumn !== null &&
            !this.props.GetFormatColumn === false
            ? false
            : true;
        } else if (
          this.props.GetSourceTable === 'Delivery' &&
          isSubColumn === false &&
          this.props.GetSourceColumn !== null &&
          formatType === true
        ) {
          //2 sumber kolom "false", format tipe "true"
          return coreValidate === false &&
            !this.props.GetFormatColumn === false &&
            this.props.GetSourceColumn !== null
            ? false
            : true;
        } else if (
          this.props.GetSourceTable === 'Delivery' &&
          isSubColumn === true &&
          formatType === false &&
          this.props.GetFormatType !== 'CombineColumn'
        ) {
          //3 sumber kolom "true", format tipe false"
          return coreValidate === false && this.props.GetSubColumn !== null
            ? false
            : true;
        } else if (
          this.props.GetSourceTable === 'Delivery' &&
          isSubColumn === false &&
          formatType === false &&
          this.props.GetFormatType !== 'CombineColumn'
        ) {
          //4 sumber kolom "false", format tipe false"
          return coreValidate === false && this.props.GetSourceColumn !== null
            ? false
            : true;
        } else if (
          this.props.GetSourceTable === 'Delivery' &&
          isSubColumn === true &&
          this.props.GetFormatType === 'CombineColumn' &&
          isSecSubColumn === true
        ) {
          //5 sumber kolom "true", format tipe kombinasi, sumber sec kolom "true"
          return coreValidate === false &&
            this.props.GetSubColumn !== null &&
            this.props.GetSubColumnSec !== null &&
            !this.props.GetFormatColumn === false
            ? false
            : true;
        } else if (
          this.props.GetSourceTable === 'Delivery' &&
          isSubColumn === true &&
          this.props.GetFormatType === 'CombineColumn' &&
          isSecSubColumn === false
        ) {
          //6 sumber kolom "true", format tipe kombinasi, sumber sec kolom "false"
          return coreValidate === false &&
            this.props.GetSubColumn !== null &&
            !this.props.GetFormatColumn === false
            ? false
            : true;
        } else if (
          this.props.GetSourceTable === 'Delivery' &&
          isSubColumn === false &&
          this.props.GetFormatType === 'CombineColumn' &&
          isSecSubColumn === true
        ) {
          //7 sumber kolom "false", format tipe kombinasi, sumber sec kolom "true"
          return coreValidate === false &&
            this.props.GetSourceColumn !== null &&
            this.props.GetSubColumnSec !== null &&
            !this.props.GetFormatColumn === false
            ? false
            : true;
        } else if (
          this.props.GetSourceTable === 'Delivery' &&
          isSubColumn === false &&
          this.props.GetFormatType === 'CombineColumn' &&
          isSecSubColumn === false
        ) {
          //8 sumber kolom "false", format tipe kombinasi, sumber sec kolom "false"
          return coreValidate === false &&
            this.props.GetSourceColumn !== null &&
            this.props.GetSourceColumnSec !== null &&
            !this.props.GetFormatColumn === false
            ? false
            : true;
        } else {
          return true;
        }
      } else if (this.props.GetFormat === 'ValueDefault') {
        return coreValidate === false && !this.props.GetValueDefault === false
          ? false
          : true;
      } else {
        return coreValidate;
      }
    };
    return (
      <ModalMaterial
        className={''}
        width={'70%'}
        style={{}}
        teksTitle={
          (this.props.jenisToggle === 'AddCustomReportDataCustomer'
            ? 'TAMBAH'
            : this.props.jenisToggle === 'EditCustomReportDataCustomer'
            ? 'UBAH'
            : '') + ` KOLOM LAPORAN ${this.props.GetCustomerName}`
        }
        visible={
          (this.props.visible === true &&
            this.props.jenisToggle === 'AddCustomReportDataCustomer') ||
          this.props.jenisToggle === 'EditCustomReportDataCustomer'
            ? true
            : false
        }
        onCancel={this.props.handleCloseModal}
        content={
          <SpinMaterial
            spinning={this.props.isLoading}
            style={{}}
            size={'large'}
            content={
              <div style={{ paddingTop: '20px' }}>
                <div style={{ marginRight: 20, marginLeft: 20 }}>
                  <Row>
                    <Col span={12} style={{ paddingRight: '20px' }}>
                      <div>
                        <span>
                          <b>Judul Kolom</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          value={this.props.GetHeader}
                          disabled={false}
                          placeholder={'Contoh : WAYBILL'}
                          maxLength={100}
                          style={{ borderRadius: '10px' }}
                          onChange={this.props.handleChangeHeader}
                          onBlur={this.props.handleChangeHeader}
                          validatestatus={this.props.ValidateHeader}
                          help={this.props.helpValidateHeader}
                        />
                      </div>
                    </Col>
                    <Col span={12} style={{ paddingLeft: '20px' }}>
                      <div>
                        <span>
                          <b>Posisi</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          value={this.props.GetPosition}
                          className={''}
                          disabled={
                            this.props.jenisToggle ===
                            'AddCustomReportDataCustomer'
                              ? true
                              : false
                          }
                          addonBefore={''}
                          placeholder={'Masukkan posisi'}
                          prefix={''}
                          maxLength={''}
                          style={{ borderRadius: '10px' }}
                          size=""
                          type={''}
                          onChange={this.props.handleChangePosition}
                          onBlur={this.props.handleChangePosition}
                          validatestatus={this.props.ValidatePosition}
                          help={this.props.helpValidatePosition}
                          onPressEnter={''}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div>
                  <div style={{ marginRight: 20, marginLeft: 20 }}>
                    <Row>
                      <Col span={12} style={{ paddingRight: '20px' }}>
                        <div>
                          <span>
                            <b>Format</b>
                          </span>
                          <Form.Item
                            validateStatus={this.props.ValidateFormat}
                            help={this.props.helpValidateFormat}
                          >
                            <Select
                              style={{ width: '100%' }}
                              disabled={false}
                              placeholder="Pilih Jenis Format"
                              onSelect={this.props.handleResetFormat}
                              optionFilterProp="children"
                              value={
                                this.props.GetFormat === null ? (
                                  <span style={{ color: '#969696' }}>
                                    Pilih jenis format
                                  </span>
                                ) : (
                                  this.props.GetFormat
                                )
                              }
                              onChange={this.props.handleChangeFormat}
                              onBlur={this.props.handleBlurFormat}
                            >
                              {CustomReportFormat.map(
                                (data: any, index: any) => (
                                  <Option key={index} value={data.value}>
                                    {data.label}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={12} style={{ paddingLeft: '20px' }}>
                        <div>
                          <span>
                            <b>Value</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetValueDefault}
                            className={''}
                            disabled={
                              this.props.GetFormat === 'ValueDefault'
                                ? false
                                : true
                            }
                            addonBefore={''}
                            placeholder={'Contoh : RDS'}
                            prefix={''}
                            maxLength={30}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.handleChangeValueDefault}
                            onBlur={this.props.handleChangeValueDefault}
                            validatestatus={''}
                            help={''}
                            onPressEnter={''}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {this.props.GetFormat === 'SourceTabel' ? (
                    <div style={{}}>
                      <hr
                        style={{
                          display: 'block',
                          height: '1px',
                          border: '0',
                          borderTop: '1px solid #ccc',
                          margin: '0',
                          padding: '0',
                        }}
                      />
                      <div
                        style={{
                          marginRight: 20,
                          marginLeft: 20,
                          paddingTop: '17px',
                        }}
                      >
                        <Row>
                          <Col span={12} style={{ paddingRight: '20px' }}>
                            <div>
                              <span>
                                <b>Sumber Tabel</b>
                              </span>
                              <Form.Item
                                validateStatus={this.props.ValidateSourceTable}
                                help={this.props.helpValidateSourceTable}
                              >
                                <Select
                                  style={{
                                    width: '100%',
                                    borderRadius: '10px',
                                  }}
                                  onSelect={this.props.handleChangeSourceTable}
                                  optionFilterProp="children"
                                  showSearch
                                  value={
                                    this.props.GetSourceTable === null ? (
                                      <span style={{ color: '#969696' }}>
                                        Pilih sumber tabel
                                      </span>
                                    ) : (
                                      this.props.GetSourceTable
                                    )
                                  }
                                  onChange={this.props.handleChangeSourceTable}
                                  onBlur={this.props.handleBlurSourceTable}
                                >
                                  <Option value="Delivery">Master Data</Option>
                                  <Option value="DeliveryHistory">
                                    Riwayat Pengiriman
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={12} style={{ paddingLeft: '20px' }}>
                            <div>
                              <span>
                                <b>Format Tipe</b>
                              </span>
                              <Row>
                                <Col span={14}>
                                  <Form.Item validateStatus={''} help={''}>
                                    <Select
                                      style={{
                                        width: '100%',
                                        borderRadius: '10px',
                                      }}
                                      onSelect={
                                        this.props.handleChangeFormatType
                                      }
                                      optionFilterProp="children"
                                      showSearch
                                      value={
                                        this.props.GetFormatType === null ? (
                                          <span style={{ color: '#969696' }}>
                                            Pilih tipe format
                                          </span>
                                        ) : (
                                          this.props.GetFormatType
                                        )
                                      }
                                      onChange={
                                        this.props.handleChangeFormatType
                                      }
                                      onBlur={this.props.handleBlurFormatType}
                                    >
                                      <Option value="Empty">
                                        <span style={{ color: '#969696' }}>
                                          Pilih format tipe
                                        </span>
                                      </Option>
                                      {CustomReportFormatType.filter(value => {
                                        if (
                                          this.props.GetSourceTable ===
                                          'DeliveryHistory'
                                        ) {
                                          return (
                                            value.value === 'DeliveryStatus'
                                          );
                                        } else {
                                          return value;
                                        }
                                      }).map((data: any, index: any) => (
                                        <Option key={index} value={data.value}>
                                          {data.label}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={10}>
                                  <div style={{ marginTop: 8, marginLeft: 32 }}>
                                    <Checkbox
                                      onChange={
                                        this.props.handleChangeCheckBoxPetik
                                      }
                                      checked={this.props.GetCheckBoxPetik}
                                      disabled={
                                        this.props.GetSourceTable ===
                                        'DeliveryHistory'
                                      }
                                    />
                                    <span style={checkboxSpan}>
                                      Tambahkan Petik
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div style={{ marginRight: 20, marginLeft: 20 }}>
                        <Row>
                          <Col span={12} style={{ paddingRight: '20px' }}>
                            <div>
                              <span>
                                <b>Sumber Kolom</b>
                              </span>
                              {this.props.GetSourceColumn ===
                                'ReceiverTitle(STRING)' ||
                              this.props.GetSourceColumn ===
                                'ReturnedReason(STRING)' ||
                              this.props.GetSourceColumn ===
                                'ReturnedReason1(STRING)' ||
                              this.props.GetSourceColumn ===
                                'ReturnedReason2(STRING)' ||
                              this.props.GetSourceColumn ===
                                'ReturnedReason3(STRING)' ? (
                                <Row>
                                  <Col span={12}>
                                    <Form.Item
                                      validateStatus={
                                        this.props.ValidateSourceColumn
                                      }
                                      help={this.props.helpValidateSourceColumn}
                                    >
                                      <Select
                                        loading={
                                          this.props.LoadingCustomer === true &&
                                          this.props.TypeLoadingCustomer ===
                                            'selectColumnName'
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          this.props.GetSourceTable === null
                                            ? true
                                            : this.props.GetSourceTable ===
                                              'Empty'
                                            ? true
                                            : false
                                        }
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        showSearch
                                        value={
                                          this.props.GetSourceColumn ===
                                          null ? (
                                            <span style={{ color: '#969696' }}>
                                              Pilih sumber kolom
                                            </span>
                                          ) : (
                                            this.props.GetSourceColumn
                                          )
                                        }
                                        onChange={
                                          this.props.handleChangeSourceColumn
                                        }
                                        onFocus={
                                          this.props.handleGetListSourceColumn
                                        }
                                        onSelect={
                                          this.props.handleChangeSourceColumn
                                        }
                                        onBlur={
                                          this.props.handleBlurSourceColumn
                                        }
                                      >
                                        {this.props.GetListSourceColumn.map(
                                          (data: any, index: any) => (
                                            <Option key={index} value={data}>
                                              {data}
                                            </Option>
                                          )
                                        )}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <div
                                      style={{ marginTop: 8, marginLeft: 32 }}
                                    >
                                      <Checkbox
                                        onChange={
                                          this.props.handleChangeCheckBoxOthers
                                        }
                                        checked={this.props.GetCheckBoxOthers}
                                      />
                                      <span style={checkboxSpan}>
                                        Tampilkan Teks Lain-lain
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                <Form.Item
                                  validateStatus={
                                    this.props.ValidateSourceColumn
                                  }
                                  help={this.props.helpValidateSourceColumn}
                                >
                                  <Select
                                    loading={
                                      this.props.LoadingCustomer === true &&
                                      this.props.TypeLoadingCustomer ===
                                        'selectColumnName'
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      this.props.GetSourceTable === null
                                        ? true
                                        : this.props.GetSourceTable === 'Empty'
                                        ? true
                                        : false
                                    }
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    showSearch
                                    value={
                                      this.props.GetSourceColumn === null ? (
                                        <span style={{ color: '#969696' }}>
                                          Pilih sumber kolom
                                        </span>
                                      ) : (
                                        this.props.GetSourceColumn
                                      )
                                    }
                                    onChange={
                                      this.props.handleChangeSourceColumn
                                    }
                                    onFocus={
                                      this.props.handleGetListSourceColumn
                                    }
                                    onSelect={
                                      this.props.handleChangeSourceColumn
                                    }
                                    onBlur={this.props.handleBlurSourceColumn}
                                  >
                                    {this.props.GetSourceTable ===
                                    'DeliveryHistory' ? (
                                      <Option value={'DeliveryHistory'}>
                                        Riwayat Pengiriman(Pickup, Kirim,
                                        Selesai)
                                      </Option>
                                    ) : (
                                      this.props.GetListSourceColumn.map(
                                        (data: any, index: any) => (
                                          <Option key={index} value={data}>
                                            {data}
                                          </Option>
                                        )
                                      )
                                    )}
                                  </Select>
                                </Form.Item>
                              )}
                            </div>
                          </Col>
                          <Col span={12} style={{ paddingLeft: '20px' }}>
                            {(this.props.GetSourceTable === 'Delivery' ||
                              this.props.GetSourceTable ===
                                'DeliveryHistory') &&
                            (this.props.GetSourceColumn === 'DeliveryStatus' ||
                              this.props.GetSourceColumn ===
                                'DeliveryHistory') &&
                            this.props.GetFormatType === 'DeliveryStatus' ? (
                              <div>
                                <span>
                                  <b>Replace Success</b>
                                </span>
                                <FormInputTextMaterial
                                  validateType={true}
                                  value={this.props.GetReplaceSuccess}
                                  className={''}
                                  disabled={false}
                                  addonBefore={''}
                                  placeholder={'Diterima'}
                                  prefix={''}
                                  maxLength={''}
                                  style={{ borderRadius: '10px' }}
                                  size=""
                                  type={''}
                                  onChange={
                                    this.props.handleChangeReplaceSuccess
                                  }
                                  onBlur={this.props.handleChangeReplaceSuccess}
                                  validatestatus={
                                    this.props.ValidateReplaceSuccess
                                  }
                                  help={this.props.helpValidateReplaceSuccess}
                                  onPressEnter={''}
                                />
                              </div>
                            ) : this.props.GetFormatType === 'CombineColumn' ? (
                              <div>
                                <span>
                                  <b>Sumber Kolom 2</b>
                                </span>
                                <Form.Item
                                  validateStatus={
                                    this.props.ValidateSourceColumnSec
                                  }
                                  help={this.props.helpValidateSourceColumnSec}
                                >
                                  <Select
                                    loading={
                                      this.props.LoadingCustomer === true &&
                                      this.props.TypeLoadingCustomer ===
                                        'selectColumnNameSec'
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      this.props.GetSourceTable === null
                                        ? true
                                        : this.props.GetSourceTable === 'Empty'
                                        ? true
                                        : false
                                    }
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    showSearch
                                    value={
                                      this.props.GetSourceColumnSec === null ? (
                                        <span style={{ color: '#969696' }}>
                                          Pilih sumber kolom 2
                                        </span>
                                      ) : (
                                        this.props.GetSourceColumnSec
                                      )
                                    }
                                    onChange={
                                      this.props.handleChangeSourceColumnSec
                                    }
                                    onFocus={
                                      this.props.handleGetListSourceColumnSec
                                    }
                                    onSelect={
                                      this.props.handleChangeSourceColumnSec
                                    }
                                    onBlur={
                                      this.props.handleBlurSourceColumnSec
                                    }
                                  >
                                    {this.props.GetListSourceColumnSec.map(
                                      (data: any, index: any) => (
                                        <Option key={index} value={data}>
                                          {data}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </Form.Item>
                              </div>
                            ) : (
                              <div>
                                <span>
                                  <b style={{ paddingRight: '8px' }}>
                                    Format Kolom
                                  </b>
                                  <Tooltip
                                    title={
                                      <div>
                                        <div>
                                          <span>Contoh :</span>
                                        </div>
                                        <div>
                                          <span>
                                            {'*DateTime = MM-DD-YYYY'}
                                          </span>
                                        </div>
                                        <div>
                                          <span>
                                            {'*Fungsi = ToUpper(<value>)'}
                                          </span>
                                        </div>
                                        <div>
                                          <span>{'*Imbuhan = ‘<value>'}</span>
                                        </div>
                                      </div>
                                    }
                                  >
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                                <FormInputTextMaterial
                                  validateType={true}
                                  value={this.props.GetFormatColumn}
                                  className={''}
                                  disabled={
                                    this.props.GetFormatType === null
                                      ? true
                                      : this.props.GetFormatType === 'Empty'
                                      ? true
                                      : this.props.GetFormatType ===
                                        'CodeStatusReciver'
                                      ? true
                                      : this.props.GetFormatType ===
                                        'DeliveryStatus'
                                      ? true
                                      : this.props.GetFormatType ===
                                        'FreeTextStatus'
                                      ? true
                                      : this.props.GetFormatType === 'Reverse'
                                      ? true
                                      : false
                                  }
                                  addonBefore={''}
                                  placeholder={
                                    this.props.GetFormatType === 'DateTime'
                                      ? 'Contoh : MM-DD-YYYY'
                                      : this.props.GetFormatType === 'Fungsi'
                                      ? 'Contoh : ToUpper(<value>)'
                                      : this.props.GetFormatType === 'Imbuhan'
                                      ? 'Contoh : ‘<value>'
                                      : 'Masukan format kolom bila perlu'
                                  }
                                  style={{ borderRadius: '10px' }}
                                  onChange={this.props.handleChangeFormatColumn}
                                  onBlur={this.props.handleChangeFormatColumn}
                                  validatestatus={
                                    this.props.ValidateFormatColumn
                                  }
                                  help={this.props.helpValidateFormatColumn}
                                />
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <div style={{ marginRight: 20, marginLeft: 20 }}>
                        <Row>
                          <Col span={12} style={{ paddingRight: '20px' }}>
                            {this.props.GetSourceTable === 'DeliveryStatus' ? (
                              <div>
                                <span>
                                  <b>Jenis Riwayat</b>
                                </span>
                                <Form.Item
                                  validateStatus={
                                    this.props.ValidateHistoryType
                                  }
                                  help={this.props.helpValidateHistoryType}
                                >
                                  <Select
                                    style={{
                                      width: '100%',
                                      borderRadius: '10px',
                                    }}
                                    onSelect={
                                      this.props.handleChangeHistoryType
                                    }
                                    optionFilterProp="children"
                                    showSearch
                                    value={
                                      this.props.GetHistoryType === null ? (
                                        <span style={{ color: '#969696' }}>
                                          Pilih jenis Riwayat
                                        </span>
                                      ) : (
                                        this.props.GetHistoryType
                                      )
                                    }
                                    disabled={
                                      this.props.GetSourceTable === null
                                        ? true
                                        : false
                                    }
                                    onChange={
                                      this.props.handleChangeHistoryType
                                    }
                                    onBlur={this.props.handleBlurHistoryType}
                                  >
                                    {HistoryType.map(
                                      (data: any, index: any) => (
                                        <Option key={index} value={data.value}>
                                          {data.label}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </Form.Item>
                              </div>
                            ) : this.props.GetSourceTable === 'Delivery' ? (
                              <div>
                                <span>
                                  <b>Tambah Sub Kolom</b>
                                </span>
                                <Form.Item
                                  validateStatus={this.props.ValidateSubColumn}
                                  help={this.props.helpValidateSubColumn}
                                >
                                  <Select
                                    loading={
                                      this.props.LoadingCustomer === true &&
                                      this.props.TypeLoadingCustomer ===
                                        'selectSubColumn'
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      this.props.GetSourceColumn !==
                                        'ShipToAddress(STRING)' &&
                                      this.props.GetSourceColumn !==
                                        'CustomStatusCode(STRING)' &&
                                      this.props.GetSourceColumn !==
                                        'AdditionalData(STRING)' &&
                                      this.props.GetSourceColumn !==
                                        'AdditionalAddress(STRING)' &&
                                      this.props.GetSourceColumn !==
                                        'ReceiverLocation(STRING)' &&
                                      this.props.GetSourceColumn !== 'Branch' &&
                                      this.props.GetSourceColumn !== 'Courier'
                                        ? true
                                        : false
                                    }
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    showSearch
                                    value={
                                      this.props.GetSubColumn === null ||
                                      this.props.GetSubColumn === 'Empty' ? (
                                        <span style={{ color: '#969696' }}>
                                          Pilih sub kolom
                                        </span>
                                      ) : (
                                        this.props.GetSubColumn
                                      )
                                    }
                                    onChange={this.props.handleChangeSubColumn}
                                    onFocus={this.props.handleGetListSubColumn}
                                    onSelect={this.props.handleChangeSubColumn}
                                    onBlur={this.props.handleBlurSubColumn}
                                  >
                                    {this.props.GetListSubColumn.map(
                                      (data: any, index: any) => (
                                        <Option key={index} value={data}>
                                          {data}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </Form.Item>
                              </div>
                            ) : null}
                          </Col>
                          <Col span={12} style={{ paddingLeft: '20px' }}>
                            {(this.props.GetSourceTable === 'Delivery' ||
                              this.props.GetSourceTable ===
                                'DeliveryHistory') &&
                            (this.props.GetSourceColumn === 'DeliveryStatus' ||
                              this.props.GetSourceColumn ===
                                'DeliveryHistory') &&
                            this.props.GetFormatType === 'DeliveryStatus' ? (
                              <div>
                                <span>
                                  <b>Replace Return</b>
                                </span>
                                <FormInputTextMaterial
                                  validateType={true}
                                  value={this.props.GetReplaceReturn}
                                  className={''}
                                  disabled={false}
                                  addonBefore={''}
                                  placeholder={'Dikembalikan'}
                                  prefix={''}
                                  maxLength={''}
                                  style={{ borderRadius: '10px' }}
                                  size=""
                                  type={''}
                                  onChange={
                                    this.props.handleChangeReplaceReturn
                                  }
                                  onBlur={this.props.handleChangeReplaceReturn}
                                  validatestatus={
                                    this.props.ValidateReplaceReturn
                                  }
                                  help={this.props.helpValidateReplaceReturn}
                                  onPressEnter={''}
                                />
                              </div>
                            ) : this.props.GetFormatType === 'CombineColumn' ? (
                              <div>
                                <span>
                                  <b>Tambah Sub Kolom 2</b>
                                </span>
                                <Form.Item
                                  validateStatus={
                                    this.props.ValidateSubColumnSec
                                  }
                                  help={this.props.helpValidateSubColumnSec}
                                >
                                  <Select
                                    loading={
                                      this.props.LoadingCustomer === true &&
                                      this.props.TypeLoadingCustomer ===
                                        'selectSubColumnSec'
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      this.props.GetSourceColumnSec !==
                                        'ShipToAddress(STRING)' &&
                                      this.props.GetSourceColumn !==
                                        'CustomStatusCode(STRING)' &&
                                      this.props.GetSourceColumnSec !==
                                        'AdditionalData(STRING)' &&
                                      this.props.GetSourceColumnSec !==
                                        'AdditionalAddress(STRING)' &&
                                      this.props.GetSourceColumnSec !==
                                        'ReceiverLocation(STRING)' &&
                                      this.props.GetSourceColumnSec !==
                                        'Branch' &&
                                      this.props.GetSourceColumnSec !==
                                        'Courier'
                                        ? true
                                        : false
                                    }
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    showSearch
                                    value={
                                      this.props.GetSubColumnSec === null ||
                                      this.props.GetSubColumnSec === 'Empty' ? (
                                        <span style={{ color: '#969696' }}>
                                          Pilih sub kolom 2
                                        </span>
                                      ) : (
                                        this.props.GetSubColumnSec
                                      )
                                    }
                                    onChange={
                                      this.props.handleChangeSubColumnSec
                                    }
                                    onFocus={
                                      this.props.handleGetListSubColumnSec
                                    }
                                    onSelect={
                                      this.props.handleChangeSubColumnSec
                                    }
                                    onBlur={this.props.handleBlurSubColumnSec}
                                  >
                                    {this.props.GetListSubColumnSec.map(
                                      (data: any, index: any) => (
                                        <Option key={index} value={data}>
                                          {data}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </Form.Item>
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </div>

                      <div style={{ marginRight: 20, marginLeft: 20 }}>
                        <Row>
                          <Col span={12} style={{ paddingRight: '20px' }}>
                            {this.props.GetSourceTable === 'DeliveryStatus' ? (
                              <div>
                                <span>
                                  <b>Pengiriman Ke</b>
                                </span>
                                <Form.Item
                                  validateStatus={this.props.ValidateDeliveryTo}
                                  help={this.props.helpValidateDeliveryTo}
                                >
                                  <Select
                                    style={{
                                      width: '100%',
                                      borderRadius: '10px',
                                    }}
                                    onSelect={this.props.handleChangeDeliveryTo}
                                    optionFilterProp="children"
                                    showSearch
                                    value={
                                      this.props.GetDeliveryTo === null ? (
                                        <span style={{ color: '#969696' }}>
                                          Pilih pengiriman ke
                                        </span>
                                      ) : (
                                        this.props.GetDeliveryTo
                                      )
                                    }
                                    disabled={
                                      this.props.GetHistoryType === null
                                        ? true
                                        : false
                                    }
                                    onChange={this.props.handleChangeDeliveryTo}
                                    onBlur={this.props.handleBlurDeliveryTo}
                                  >
                                    <Option value={1}>1</Option>
                                    <Option value={2}>2</Option>
                                    <Option value={3}>3</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            ) : null}
                          </Col>
                          <Col span={12} style={{ paddingLeft: '20px' }}>
                            {(this.props.GetSourceTable === 'Delivery' ||
                              this.props.GetSourceTable ===
                                'DeliveryHistory') &&
                            (this.props.GetSourceColumn === 'DeliveryStatus' ||
                              this.props.GetSourceColumn ===
                                'DeliveryHistory') &&
                            this.props.GetFormatType === 'DeliveryStatus' ? (
                              <div>
                                <span>
                                  <b>Replace Onprocess</b>
                                </span>
                                <FormInputTextMaterial
                                  validateType={true}
                                  value={this.props.GetReplaceOnProcess}
                                  className={''}
                                  disabled={false}
                                  addonBefore={''}
                                  placeholder={'Diproses'}
                                  prefix={''}
                                  maxLength={''}
                                  style={{ borderRadius: '10px' }}
                                  size=""
                                  type={''}
                                  onChange={
                                    this.props.handleChangeReplaceOnProcess
                                  }
                                  onBlur={
                                    this.props.handleChangeReplaceOnProcess
                                  }
                                  validatestatus={
                                    this.props.ValidateReplaceOnProcess
                                  }
                                  help={this.props.helpValidateReplaceOnProcess}
                                  onPressEnter={''}
                                />
                              </div>
                            ) : this.props.GetFormatType === 'CombineColumn' ? (
                              <div>
                                <span>
                                  <b style={{ paddingRight: '8px' }}>
                                    Format Kolom
                                  </b>
                                  <Tooltip
                                    title={
                                      <div>
                                        <div>
                                          <span>Contoh :</span>
                                        </div>
                                        <div>
                                          <span>
                                            {'*DateTime = MM-DD-YYYY'}
                                          </span>
                                        </div>
                                        <div>
                                          <span>
                                            {'*Fungsi = ToUpper(<value>)'}
                                          </span>
                                        </div>
                                        <div>
                                          <span>{'*Imbuhan = ‘<value>'}</span>
                                        </div>
                                        <div>
                                          <span>
                                            {'*Kombinasi = <value1>(<value2>)'}
                                          </span>
                                        </div>
                                      </div>
                                    }
                                  >
                                    <Icon type="question-circle-o" />
                                  </Tooltip>
                                </span>
                                <FormInputTextMaterial
                                  validateType={true}
                                  value={this.props.GetFormatColumn}
                                  className={''}
                                  disabled={
                                    this.props.GetFormatType === null
                                      ? true
                                      : this.props.GetFormatType === 'Empty'
                                      ? true
                                      : this.props.GetFormatType ===
                                        'CodeStatusReciver'
                                      ? true
                                      : this.props.GetFormatType ===
                                        'DeliveryStatus'
                                      ? true
                                      : this.props.GetFormatType ===
                                        'FreeTextStatus'
                                      ? true
                                      : this.props.GetFormatType === 'Reverse'
                                      ? true
                                      : false
                                  }
                                  addonBefore={''}
                                  placeholder={
                                    this.props.GetFormatType === 'DateTime'
                                      ? 'Contoh : MM-DD-YYYY'
                                      : this.props.GetFormatType === 'Fungsi'
                                      ? 'Contoh : ToUpper(<value>)'
                                      : this.props.GetFormatType === 'Imbuhan'
                                      ? 'Contoh : "<value>'
                                      : this.props.GetFormatType ===
                                        'CombineColumn'
                                      ? 'Contoh : <value1>(<value2>)'
                                      : 'Masukan format kolom bila perlu'
                                  }
                                  prefix={''}
                                  maxLength={''}
                                  style={{ borderRadius: '10px' }}
                                  size=""
                                  type={''}
                                  onChange={this.props.handleChangeFormatColumn}
                                  onBlur={this.props.handleChangeFormatColumn}
                                  validatestatus={
                                    this.props.ValidateFormatColumn
                                  }
                                  help={this.props.helpValidateFormatColumn}
                                  onPressEnter={''}
                                />
                              </div>
                            ) : null}

                            {this.props.GetSourceTable === 'DeliveryHistory' &&
                              this.props.GetSourceColumn ===
                                'DeliveryHistory' &&
                              this.props.GetFormatType === 'DeliveryStatus' && (
                                <div>
                                  <span>
                                    <b>Replace Onhold</b>
                                  </span>
                                  <FormInputTextMaterial
                                    validateType={true}
                                    value={this.props.GetReplaceOnHold}
                                    className={''}
                                    disabled={false}
                                    addonBefore={''}
                                    placeholder={'Ditunda'}
                                    prefix={''}
                                    maxLength={''}
                                    style={{ borderRadius: '10px' }}
                                    size=""
                                    type={''}
                                    onChange={
                                      this.props.handleChangeReplaceOnHold
                                    }
                                    onBlur={
                                      this.props.handleChangeReplaceOnHold
                                    }
                                    validatestatus={
                                      this.props.ValidateReplaceOnHold
                                    }
                                    help={this.props.helpValidateReplaceOnHold}
                                    onPressEnter={''}
                                  />
                                </div>
                              )}

                            <span>
                              <b style={{ paddingRight: '8px' }}>
                                Panjang Karakter
                              </b>
                              <Tooltip
                                title={'Untuk menentukan panjang karakter'}
                              >
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            </span>
                            <Form.Item>
                              <InputNumber
                                min={1}
                                max={999}
                                // defaultValue={1}
                                placeholder={'Contoh : 20'}
                                style={{
                                  borderRadius: '10px',
                                  minWidth: '100px',
                                  width: '100%',
                                }}
                                onChange={this.props.handleChangeCharLength}
                                value={this.props.GetCharLength}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div style={{ textAlign: 'right', marginRight: 20 }}>
                  <ButtonMaterial
                    disabled={valiateBtn()}
                    handleSubmit={this.props.handleSubmitCustomReport}
                    teksButton={'Simpan'}
                    size={''}
                    shape={'default'}
                    className={''}
                    type={'primary'}
                    style={
                      valiateBtn() === true
                        ? { height: '35px', borderRadius: '12px' }
                        : {
                            colorFont: '#11BEFF',
                            height: '35px',
                            borderRadius: '12px',
                            background: '#11BEFF',
                            borderColor: '#11BEFF',
                          }
                    }
                    icon={''}
                  />
                </div>
              </div>
            }
          />
        }
      />
    );
  }
}
