import 'moment/locale/id';
import 'styles/DeliveryStatus/index.css';

import { Button, Card, Col, Row } from 'antd';
import {
  EnumContenTrackingDelivery,
  EnumHeaderTrackingDelivery,
} from 'module/DeliveryStatus/Container/EnumTrackingDelivery';

import ModalMaterial from 'materials/ModalMaterial';
import React from 'react';
import moment from 'moment';

moment.locale('id');

interface IProps {
  History: any;
  visible: any;
  typeToggle: any;
  getImageHistory: any;
  getSignatureHistory: any;
  handleModalImage: any;
  handleModalSignature: any;
  handleCloseModal: any;
  index?: any;
}
export default function TrackingCardBarcodeComponent({
  History,
  visible,
  typeToggle,
  getImageHistory,
  getSignatureHistory,
  handleModalImage,
  handleModalSignature,
  handleCloseModal,
  index,
}: IProps) {
  const data = History.map((data: any) => data.history);
  const count = data.length - 1;

  const styles = {
    cardBody: {
      maxHeight: '420px',
      overflow: 'auto',
    },
  };
  return (
    <div style={{ height: '100%' }}>
      <div>
        {typeToggle === 'signatureBarcodeHistory' ? (
          <ModalMaterial
            className={''}
            width={'40%'}
            style={{ paddingTop: '20px' }}
            teksTitle={'SIGNATURE'}
            visible={
              visible === true && typeToggle === 'signatureBarcodeHistory'
                ? true
                : false
            }
            onCancel={handleCloseModal}
            content={
              <div>
                <div>
                  <img
                    className="image-gallery-slide"
                    alt="Signature History"
                    src={`data:image/jpeg;base64,${getSignatureHistory}`}
                  />
                  {/* <img style={{transform: `rotate(${rotation}deg)`}} src={`data:image/jpeg;base64,${getSignature}`} /> */}
                </div>
              </div>
            }
          />
        ) : typeToggle === 'imageBarcodeHistory' ? (
          <ModalMaterial
            className={''}
            width={'40%'}
            style={{ paddingTop: '20px' }}
            teksTitle={'FOTO'}
            visible={
              visible === true && typeToggle === 'imageBarcodeHistory'
                ? true
                : false
            }
            onCancel={handleCloseModal}
            content={
              <div>
                <img
                  className="image-gallery-slide"
                  alt="History"
                  src={`data:image/jpeg;base64,${getImageHistory}`}
                />
              </div>
            }
          />
        ) : (
          ''
        )}
      </div>
      <Card
        bodyStyle={styles.cardBody}
        style={{
          borderRadius: '10px',
          height: '520px',
          maxHeight: '100%',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        }}
        title={
          <div>
            <span style={{ fontSize: 14 }}>
              <b
                style={{
                  color: '#11BEFF',
                  paddingLeft: '15px',
                  fontSize: 15,
                }}
              >
                Riwayat Pengiriman
              </b>
            </span>
          </div>
        }
      >
        {data[index].length === 0 ? (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              height: '100%',
            }}
          >
            <img
              style={{ width: '80%', height: '50%', paddingTop: '10%' }}
              alt="Kosong"
              src={''}
            />
          </div>
        ) : (
          data[index].map((x: any, index: any) => (
            <Row className={index === count ? '' : 'line'}>
              <Col span={5}>
                <div
                  style={{
                    borderRadius: '4px',
                    background:
                      x.position === 2 && index !== 0
                        ? '#fb8c00'
                        : x.position > 2 && index !== 0
                        ? '#FF5858'
                        : index === 0
                        ? '#F5F5F5'
                        : '#7956EE',
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: 13,
                      color:
                        index === 0 &&
                        x.itemStatus === 'Resend' &&
                        x.position > 2
                          ? '#000000'
                          : index === 0 &&
                            x.itemStatus === 'Resend' &&
                            x.position <= 2
                          ? '#000000'
                          : index === 0 && x.itemStatus !== 'Resend'
                          ? '#000000'
                          : '#FFFFFF',
                    }}
                  >
                    <div>{moment(x.date).format('DD-MMM')}</div>
                    <div>{moment(x.date).format('HH:mm')}</div>
                  </span>
                </div>
              </Col>
              <Col span={19}>
                <div style={{ paddingBottom: '15px', marginLeft: '15px' }}>
                  <div>
                    <div>
                      <b style={{ color: '#969696' }}>
                        {/* <b style={{color:x.position>2&&x.itemStatus==="Resend"?"red":"#969696"}}> */}
                        {EnumHeaderTrackingDelivery(x)}
                      </b>
                    </div>
                    <div>
                      <Row>
                        <Col span={24}>
                          <div style={{ color: 'black' }}>
                            {/* <div style={{color:x.position>2&&x.itemStatus==="Resend"?"red":"black"}}> */}
                            {EnumContenTrackingDelivery(x)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      {x.signature === null ? (
                        ''
                      ) : (
                        <Button
                          type="link"
                          style={{ paddingRight: '10px' }}
                          onClick={() => handleModalSignature(x.signature)}
                        >
                          Lihat TTD
                        </Button>
                      )}
                      {x.image === null ? (
                        ''
                      ) : (
                        <Button
                          type="link"
                          onClick={() => handleModalImage(x.image)}
                        >
                          Lihat Foto
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ))
        )}
      </Card>
    </div>
  );
}
